import React from "react";
import { IoIosConstruct, IoIosPerson } from "react-icons/io";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { TbBuildingCommunity } from "react-icons/tb";
import { RiShieldFill } from "react-icons/ri";
import { RxFileText } from "react-icons/rx";
import { HiOutlineLightBulb } from "react-icons/hi";
import { BiLibrary } from "react-icons/bi";
import { MdOutlineSell, MdOutlineLeaderboard } from "react-icons/md";
import { RiSecurePaymentLine } from "react-icons/ri";
import { CiMoneyCheck1 } from "react-icons/ci";
import { GiKitchenKnives } from "react-icons/gi";

export default function Practice() {
  return (
    <section
      id="practiceArea"
      className="w-full text-slate-200  bg-lightDark  overflow-hidden  py-32"
    >
      <div id="blockHead" className="text-center mb-10">
        <h2 className="text-4xl font-semibold">
          <span className="underline underline-offset-[16px] decoration-primary">
            خب
          </span>
          رات المكتب
        </h2>
        <p className="text-gray-500 mt-4">
          تتركز خبرات المكتب في مجال التقاضي ضمن المجالات الأتية
        </p>
      </div>
      <div
        id="practiceAll"
        className="lg:wrap wrap-res flex justify-between gap-5"
      >
        {/* <div id="practiceContent" className="w-2/5">
          <h2 className="text-4xl mb-4">
            <span className="underline underline-offset-8 decoration-secondary">
              خ
            </span>
            برات المكتب
          </h2>
          <p>
            يضم المكتب فريقا من المحامين والمستشارين القانونيين الذين
            يملكونالخبرةالقانونية في اعرق الشركات المحلية والدولية اضافه الي
            قضاه سابقين وكتاب عدل وباحثين بوزارةالعدل . والذين تتنوع خبراتهم
            القانونية بما يضمن لعملاء المكتب الفهم الدقيق لواقع العمل القضائي
            والمستقر في التوجهاتالقضائية فيالمملكةالعربية السعودية بأفضل
            الممارسات وانجحالسبل بناء علي الخلفيةالمعرفيةوالخبرةالعملية بواقع
            السوق المحلي والقانوني.
          </p>
        </div> */}
        <ul
          id="practiceList"
          className="grid lg:grid-cols-3 grid-cols-2 w-full gap-6 grid-min md:text-base text-sm"
        >
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <MdOutlineSell size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">القضايا التجارية</span>
          </li>
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <MdOutlineLeaderboard size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">القضايا الإدارية</span>
          </li>
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <IoIosConstruct size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl text-base">المقاولات</span>
          </li>
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <MdOutlineRealEstateAgent
                size="1.8rem"
                className="text-primary"
              />
            </span>
            <span className="sm:md:text-xl text-base">الأنشطة العقارية</span>
          </li>
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <TbBuildingCommunity size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">تصفية الشركات</span>
          </li>
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <IoIosPerson size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">العلاقات العمالية</span>
          </li>
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <RiShieldFill size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">التامين</span>
          </li>
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <RxFileText size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">الصياغة القانونية</span>
          </li>
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <HiOutlineLightBulb size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">الاستشارات القانونية</span>
          </li>
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <BiLibrary size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">حوكمة الشركات</span>
          </li>

          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <RiSecurePaymentLine size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">الاستثمارات المالية والعقارية</span>
          </li>

          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <CiMoneyCheck1 size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">القضايا المتعلقة بالتركات</span>
          </li>
          <li className="flex items-center justify-start gap-3 md:pr-5  bg-slate-700 h-12 rounded-sm">
            <span>
              <GiKitchenKnives size="1.8rem" className="text-primary" />
            </span>
            <span className="md:text-xl">القضايا الجنائية</span>
          </li>
        </ul>
      </div>
    </section>
  );
}
