import React from "react";
import { CiMobile3, CiMail, CiLocationOn } from "react-icons/ci";
export default function ContactDetails() {
  return (
    <div id="contactDetails" className="flex gap-4 flex-col md:flex-row">
      <div className="contact_card lg:w-1/3 w-full flex flex-col items-center py-6 gap-2 border border-solid rounded border-slate-200">
        <CiMobile3 size="4rem" className="text-secondary" />
        <h3 className="text-3xl text-slate-600 font-medium">الجوال</h3>
        <p className="text-xl">
          <p>0503224780</p>
        </p>
      </div>
      <div className="contact_card lg:w-1/3 w-full flex flex-col items-center py-10 gap-2 border border-solid rounded border-slate-200">
        <CiMail size="4rem" className="text-secondary" />
        <h3 className="text-3xl text-slate-600 font-medium">
          البريد الإلكترونى
        </h3>
        <p className="text-xl text-center">
          <p>anas.rp55@gmail.com</p>
        </p>
      </div>
      <div className="contact_card lg:w-1/3 w-full flex flex-col items-center py-10 gap-2 border border-solid rounded border-slate-200">
        <CiLocationOn size="4rem" className="text-secondary" />
        <h3 className="text-3xl text-slate-600 font-medium">الموقع</h3>
        <p className="text-xl px-4">
          <p>الرياض ص.ب:6930-3262</p>
        </p>
      </div>
    </div>
  );
}
