import React from "react";
import bg from "../../assets/calltoAction.jpg";

export default function CallToAction() {
  return (
    <section
      id="callTAction"
      style={{ backgroundImage: `url(${bg})` }}
      className="relative py-40  mt-10  h-auto before:absolute before:top-0 before:right-0 before:bg-slate-800 before:opacity-60 before:left-0 before:w-full before:h-full"
    >
      <div id="blockHead" className="text-center text-white relative mb-10 z-5">
        <h2 className="text-3xl font-semibold">
          <span className="underline underline-offset-[16px] decoration-primary">
            نس
          </span>
          تخدم أرقى المعايير المهنية والأخلاقية
        </h2>
        <p className="text-gray-200">دقة خبره انجاز</p>
      </div>
      <div
        id="call-content"
        className="lg:wrap wrap-res flex flex-col items-center lg:gap-7 gap-2 text-white relative z-10"
      >
        <p className="relative z-10 lg:leading-8 leading-5 text-sm lg:text-base">
          لما تأصل في الشرع المطهر ولماهو هو واجب علينا أخلاقيا ونظاميا فإن
          الالتزام بالخصوصية والسرية التامة لعملائنا وتقديم أرقى الخدمات
          والالتزام بأخلاقيات ديننا الحنيف ولما هو من اساسيات المهنة والثقة
          والشفافية. نبذل كل جهدنا لتقديم خدماتنا على قدر من الثقة والاحترافية
          وتلبية احتياجات عملائنا بالدقة والعناية الفائقة حاملين على عاتقنا
          الاستمرار في التعلم وتطوير الذات. ونحن نؤمن بأن هذه المقومات هي التي
          تبني الثقة الوطيدة للعملاء لأنها تمثل اساسات القيم
          والمرتكزات لنجاح الأعمال.
        </p>
        <a href="#contact" className="med-btn-trans">
          تواصل معنا
        </a>
      </div>
    </section>
  );
}
