import "./App.css";
import About from "./components/about/About";
import ArticlesList from "./components/articles/ArticlesList";
import CallToAction from "./components/callToAction/CallToAction";
import Contact from "./components/contact/Contact";
import Footer from "./components/Footer";
import Header from "./components/header/Header";
import HeroSection from "./components/heroSection/HeroSection";
import Practice from "./components/practiceArea/PracticeArea";

function App() {
  return (
    <div className="overflow-hidden">
      <Header />
      <About />
      <CallToAction />
      <HeroSection />
      <Practice />
      <ArticlesList />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
