import React from "react";
import hero from "../../assets/hero.jpg";
import HeroCard from "./HeroCard";

const cards = [
  {
    icon: "RxRocket",
    title: "يعمل لدينا فريق مختار بعناية",
    description:
      "الخبرة القضائية التي تختصر على العميل سنوات من المعرفة في التعامل مع سائر النوازل القانونية يتميز أعضاء مكتبنا بغاية النزاهة والحرص والعمل الجاد والقدرة على تقييم الأوضاع القانونية وتقديم الخدمات بفاعلية وسرعة",
  },
  {
    icon: "CgList",
    title: "نعمل بمبدأ التطوير والبحث عن كل جديد",
    description:
      "يعمل المحامون والمستشارون القانونيون بمكتبنا بمبدأ البحث والاطلاع بكل ماهو جديد  من أنظمة ولوائح وأحكام قضائية",
  },

  {
    icon: "AiOutlinePieChart",
    title: "نحن نقوم بوضع أنفسنا مكان عملائنا",
    description:
      "نحن نقوم بوضع أنفسنا مكان عملائنا والتصرف بالوكالة عنه في الوقت المناسب لتحقيق أفضل وأحسن النتائج له حرصاً على رضائه.",
  },
];

function HeroSection() {
  return (
    <div id="hero" className="flex mt-0 bg-gray-100">
      <div className="flex flex-col items-center justify-center py-3 w-4/5 lg:w-1/2">
        <h1 className="text-4xl font-semibold">
          <span className="font-bold">
            <span className="underline underline-offset-8 decoration-primary">
              ل
            </span>
            ماذا
          </span>
          نحن ؟
        </h1>
        {cards.map((card) => {
          return (
            <HeroCard
              icon={card.icon}
              title={card.title}
              description={card.description}
            />
          );
        })}
      </div>
      <div className="lg:w-1/2 lg:inline-block hidden">
        <img
          src={hero}
          alt="logo"
          className="border-r-8 border-primary hidden h-full lg:block"
        />
      </div>
    </div>
  );
}

export default HeroSection;
