import React from "react";
import bgHeader from "../../assets/33.jpeg";
import Navbar from "./Navbar";
import { HiOutlineClock } from "react-icons/hi";
import { RiLinkedinFill } from "react-icons/ri";

export default function Header() {
  return (
    <header
      className="w-full h-screen bg-no-repeat bg-center bg-cover
      after:w-full after:absolute after:h-screen after:bg-lightDark 
      after:top-0 after:left-0 after:opacity-80 text-white"
      style={{ backgroundImage: `url(${bgHeader})` }}
    >
      <div className="lg:wrap wrap-res relative z-10">
        <div id="topBar" className="flex justify-between mb-5 pt-5">
          <div
            id="date"
            className="flex gap-1 items-center text-sm text-gray-300"
          >
            <HiOutlineClock className="text-secondary text-sm" />
            <span>من السبت الى الخميس </span>
          </div>
          <div id="social_icons" className="flex justify-between gap-3 text-md">
            <a href="https://www.linkedin.com/in/anas-almobadal-14b67424b">
              <RiLinkedinFill />
            </a>
          </div>
        </div>
        <Navbar />
        <div
          id="bigTitle"
          className="text-center md:mt-44 sm:px-36 mt-12 px-10 flex flex-col gap-8"
        >
          <h1 className="xl:text-6xl text-3xl font-semibold xl:leading-tight">
            تحتاج الى <span className="text-primary">الخبرة والأمانة</span>{" "}
            لإنجاز أمورك القانونية
          </h1>
          <p>
            يضم المكتب نخبة من المحامين والمستشارين القانونيين الذين يملكون
            الخبرة القانونية في أعرق القطاعات المحلية والدولية.
          </p>
          <div id="headerButtons" className="flex gap-5 justify-center">
            <a href="#contact" className="big-btn">
              اطلب استشارة
            </a>
            <a href="#contact" className="big-btn-dark">
              خدمات المكتب
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
