import React from "react";
import logo from "../assets/logo.svg";
import { BsCheck2 } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";

function Footer() {
  return (
    <div>
      <div className="flex justify-around flex-col md:flex-row items-center bg-zinc-900 h-auto px-6">
        <div className="md:w-1/3 lg:w-2/3 w-full px-8">
          <img src={logo} alt="logo" />
          <div>
            <p className="text-white">
              أُسس المكتب بعد مسيرة مهنية حافلة في عالم المحاماة والاستشارات
              القانونية ومنذ تأسيس المكتب وهو يشارك بفاعلية في مجال المحاماة
              والاستشارات القانونية للقطاع الخاص ومتابعة القضايا.
            </p>
          </div>
        </div>
        <div className="md:w-1/3 w-full p-4  text-white">
          <p className="p-4 text-primary font-semibold text-xl">خبرات المكتب</p>
          <ul className="p-4">
            <div className="flex items-center m-4">
              <BsCheck2 className="text-primary text-xl" />
              <li className="mx-2">القضايا الإدارية</li>
            </div>
            <div className="flex items-center m-4">
              <BsCheck2 className="text-primary text-xl" />
              <li className="mx-2"> القضايا التجارية</li>
            </div>
            <div className="flex items-center m-4">
              <BsCheck2 className="text-primary text-xl" />
              <li className="mx-2"> الأنشطة العقارية</li>
            </div>
            <div className="flex items-center m-4">
              <BsCheck2 className="text-primary text-xl" />
              <li className="mx-2"> الاستشارات القانونية</li>
            </div>
            <div className="flex items-center m-4">
              <BsCheck2 className="text-primary text-xl" />
              <li className="mx-2"> حوكمة الشركات</li>
            </div>
          </ul>
        </div>
        <div className="md:w-1/3 w-full p-4  text-white">
          <p className="p-4 text-primary font-semibold text-xl">
            معلومات التواصل
          </p>
          <ul className="p-4">
            <div className="flex items-center m-4">
              <BsCheck2 className="text-primary text-xl" />
              <li className="mx-2"> ص.ب:6930-3262</li>
            </div>
            <div className="flex items-center m-4 text-base md:text-sm">
              <BsCheck2 className="text-primary text-xl" />
              <li className="mx-2"> 0503224780</li>
            </div>
            <div className="flex items-center m-4">
              <BsCheck2 className="text-primary text-xl" />
              <li className="mx-2"> anas.rp55@gmail.com</li>
            </div>
          </ul>
        </div>
      </div>
      <div className="flex justify-around items-center border-t-2 border-gray-500 bg-zinc-900 p-4">
        <div className="text-white">
          <p>
            &copy; جميع الحقوق محفوظة لمكتب المحامى الدكتور انس بن رشيد المبدل
          </p>
        </div>
        <div className="flex">
          <div className="bg-primary p-2 m-2 rounded-md">
            <a href="https://www.linkedin.com/in/anas-almobadal-14b67424b">
              <BsLinkedin className="text-white text-lg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
