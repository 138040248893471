import React, { useState } from "react";
import logo from "../../assets/logo.svg";
import { HiMenuAlt1 } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function Navbar() {
  const [menu, setMenu] = useState(false);
  return (
    <nav className="relative z-10 px-6 py-2  bg-white rounded-md flex justify-between opacity-80 items-center">
      <div id="logo">
        <a href="#home">
          <img src={`${logo}`} alt="logo" className="w-64" />
        </a>
      </div>
      <ul className="hidden xl:flex gap-3 text-dark font-medium">
        <li>
          <a href="#home">الرئيسية</a>
        </li>
        <li>
          <a href="#about">عن المكتب</a>
        </li>
        <li>
          <a href="#hero">لماذا نحن</a>
        </li>
        <li>
          <a href="#practiceArea">خبرات المكتب</a>
        </li>

        <li>
          <a href="#articles">مقالات</a>
        </li>

        <li>
          <a href="#contact">اتصل بنا</a>
        </li>
      </ul>
      <a href="#contact" className="med-btn hidden xl:flex">
        اطلب استشارة
      </a>
      <button
        onClick={() => setMenu(true)}
        className="text-slate-800 xl:hidden"
        id="burgerMenu"
      >
        <HiMenuAlt1 size="1.5rem" className="text-slate-600" />
      </button>
      <div
        id="toggleMenu"
        className={`absolute ${
          menu ? "-translate-x-16 w-96 " : "-translate-x-96 w-10"
        } bg-zinc-700  top-0 left-0 -translate-y-16  h-screen transition-all duration-200`}
      >
        <ul className="text-center py-10 flex flex-col gap-5">
          <AiOutlineCloseCircle
            onClick={() => setMenu(false)}
            size="2rem"
            className="cursor-pointer mr-4"
          />
          <li>
            <a href="#home">الرئيسية</a>
          </li>
          <li>
            <a href="#about">عن المكتب</a>
          </li>
          <li>
            <a href="#hero">لماذا نحن</a>
          </li>
          <li>
            <a href="#practiceArea">خبرات المكتب</a>
          </li>

          <li>
            <a href="#articles">مقالات</a>
          </li>

          <li>
            <a href="#contact">اتصل بنا</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
