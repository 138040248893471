import React from "react";
import ContactDetails from "./ContactDetails";
// import ContactForm from "./ContactForm";

export default function Contact() {
  return (
    <section id="contact" className="lg:wrap wrap-res my-16">
      <div id="blockHead" className="text-center mb-10">
        <h2 className="text-4xl font-semibold">
          <span className="underline underline-offset-[11px] decoration-primary">
            تو
          </span>
          اصل معنا
        </h2>
      </div>
      <ContactDetails />
      {/* <ContactForm /> */}
    </section>
  );
}
