import React from "react";
import aboutPhoto from "../../assets/middle-east-lead.jpg";

export default function About() {
  return (
    <section id="about" className="lg:wrap wrap-res mt-16">
      <div id="blockHead" className="text-center mb-10">
        <h2 className="text-4xl font-semibold">
          <span className="underline underline-offset-8 decoration-primary">
            ن
          </span>
          بذة عن المكتب
        </h2>
        <p className="text-gray-500">
          عقد من الخبرة فى الاعمال القانونية والقضائية
        </p>
      </div>
      <div className="flex justify-between items-start gap-5 ">
        <div id="contentPart" className=" w-full flex flex-col gap-4">
          <h4 id="welcomeTitle" className="small-title">
            مرحباً بك في مكتب الدكتور أنس بن رشيد المبدل
          </h4>
          <h2 className="medium-title">
            {" "}
            نبذة عن مكتب الدكتور انس بن رشيد المبدل للمحاماة
          </h2>
          <p>
            أُسس المكتب بعد مسيرة مهنية حافلة في عالم القضاء والأعمال القانونية
            ومنذ تأسيس المكتب وهو يشارك بفاعلية في مجال المحاماة والاستشارات
            القانونية لكافة القطاعات ومتابعة القضايا.
            <p>
              متميزون بالتأصيل التأهل القانوني والجودة العالية والتخصص المهني في
              مجالات عديدة تأتي في مقدمتها التقاضي والترافع وتقديم الاستشارات
              القانونية
            </p>
          </p>
          <h4 id="welcomeTitle" className="small-title">
            رسالتنا
          </h4>
          <p>
            الصدق والأمانة وأن نكون قيمة مضافة لجميع عملائنا سواءً كانوا شركات
            أو أفراد - في كافة المجالات
          </p>
          <div id="buttons" className="flex gap-2 mt-10 justify-start">
            <a href="#services" className="med-btn">
              خدمات المكتب
            </a>
            <a href="#services" className="med-btn-dark">
              تواصل معنا
            </a>
          </div>
        </div>
        {/* <div
          id="mediaPart"
          className="md:w-1/2 md:block hidden justify-self-end "
        >
          <img
            src={aboutPhoto}
            className="w-full border-8 border-gray-100"
            alt="About"
          />
        </div> */}
      </div>
    </section>
  );
}
