import React from "react";
import { RxRocket } from "react-icons/rx";
import { AiOutlinePieChart } from "react-icons/ai";

import { CgList } from "react-icons/cg";

function HeroCard({ icon, title, description }) {
  let icons;
  if (icon === "RxRocket") {
    icons = <RxRocket className="text-white text-3xl" />;
  } else if (icon === "AiOutlinePieChart") {
    icons = <AiOutlinePieChart className="text-white text-3xl" />;
  } else if (icon === "CgList") {
    icons = <CgList className="text-white text-3xl" />;
  }
  return (
    <div className="flex items-center relative">
      <div className="bg-primary w-16 h-16 rounded-full absolute -left-10 flex items-center justify-center">
        {icons}
      </div>
      <div className="my-5 mx-12 hero">
        <h3 className="text-xl font-semibold font-serif">{title}</h3>
        <p className="">{description}</p>
      </div>
    </div>
  );
}

export default HeroCard;
