import React from "react";
import img_1 from "../../assets/1.jpg";
import img_2 from "../../assets/2.jpg";
import img_3 from "../../assets/3.jpg";
import img_4 from "../../assets/4.jpg";
import img_5 from "../../assets/5.jpg";
import img_6 from "../../assets/6.jpg";
export default function ArticlesList() {
  return (
    <section id="articles" className="lg:wrap wrap-res my-32">
      <div id="blockHead" className="text-center relative mb-10 z-5">
        <h2 className="text-4xl font-semibold">
          <span className="underline underline-offset-[16px] decoration-primary">
            مق
          </span>
          الات مهمة
        </h2>
      </div>
      <ul className="grid lg:grid-cols-3 grid-cols-1">
        <li
          className=" flex flex-col justify-start gap-5 p-5 bg-cover w-full h-96 text-white relative after:bg-black after:absolute after:top-0 after:opacity-60 after:h-96 after:right-0 after:w-full"
          style={{ backgroundImage: `url(${img_1})` }}
        >
          <h4 className="font-bold text-2xl z-50 relative">
            إنكار المحرر الرسمي
          </h4>
          <p className=" z-50 relative">
            المحرَّر العادي (غير الرسمي) حجة على من قام بالتوقيع عليه؛ وإذا تم
            الاحتجاج عليه به أمام المحكمة يجب أن يكون الإنكار صراحة لما هو منسوب
            إليه من خط أو التوقيع أو ختم أو بصمة، من احتج عليه بمحرَّر عادي
            وناقش موضوعه أمام المحكمة فلا يقبل منه أن ينكر بعد ذلك صحته. وفق
            المادة 29 من نظام الاثبات
          </p>
        </li>
        <li
          className="flex flex-col justify-start gap-5 p-5 w-full bg-cover h-96 text-white relative after:bg-black after:absolute after:top-0 after:opacity-60 after:h-96 after:right-0 after:w-full"
          style={{ backgroundImage: `url(${img_2})` }}
        >
          <h4 className="font-bold text-2xl z-50 relative">رد غرامة التأخير</h4>
          <p className=" z-50 relative">
            قررت المحكمة الإدارية العليا بتاريخ 9/9/1442هـ ، بالإلزام برد غرامة
            التأخير في العقود الإدارية إذا كانت الجهة تأخرت في صرف المستخلصات،
            ذلك أن التأخير في تلك الحالة راجع إلى إخلال الجهة في التزاماتها في
            صرف المستخلصات في آجالها المقررة، كما قررت في حكمها : بأن مدة صرف
            المستخلص ينبغي ألا تزيد عن (٦٠) يوما
          </p>
        </li>
        <li
          className="flex flex-col justify-start gap-5 p-5 w-full h-96 text-white relative bg-cover after:bg-black after:absolute after:top-0 after:opacity-60 after:h-96 after:right-0 after:w-full"
          style={{ backgroundImage: `url(${img_3})` }}
        >
          <h4 className="font-bold text-2xl z-50 relative">
            تعارض عقد التأسيس مع اتفاقية سابقة
          </h4>
          <p className=" z-50 relative">
            قررت المحكمة التجارية: أن الحاكم بين الطرفين هو عقد التأسيس المبرم
            بينهما، والموقع من قبلهما، والموثق لدى كاتب العدل، وهو الذي التقت
            عنده آخر إرادة الطرفين، وتم التراضي بينهما عليه .. وهو ما يعني أن
            عقد التأسيس لاحق للاتفاقية التي يستند إليها المدعي، ومعلوم أن اللاحق
            ينسخ السابق، عقد التأسيس هو آخر اتفاق تم بين الطرفين، وهو المعتبر
            بينهما. قضية الاستئناف رقم ٢٨/ق لعام ١٤٣٩ هـ -المتعلقة بالقضية
            التجارية رقم ٤٤٨/٣/ق لعام ١٤٣٨هـ
          </p>
        </li>
        <li
          className="flex flex-col justify-start gap-5 p-5 w-full h-96 text-white relative bg-cover after:bg-black after:absolute after:top-0 after:opacity-60 after:h-96 after:right-0 after:w-full"
          style={{ backgroundImage: `url(${img_4})` }}
        >
          <h4 className="font-bold text-2xl z-50 relative">
            الحكم الحضوري في نظام المحاكم التجارية
          </h4>
          <p className=" z-50 relative">
            يعتبر الحكم في حق المدعى عليه حضوريا إذا تبلغ المدعى عليه لشخصه أو
            وكيله، أو حضر أي منهما في أي جلسة أمام المحكمة، أو قدم مذكرة بدفاعه،
            عدت الخصومة حضورية ولو تخلف بعد ذلك، وذلك وفق المادة (١/٣٠) من نظام
            المحاكم التجارية
          </p>
        </li>
        <li
          className="flex flex-col bg-cover justify-start gap-5 p-5 w-full h-96 text-white relative after:bg-black after:absolute after:top-0 after:opacity-60 after:h-96 after:right-0 after:w-full"
          style={{ backgroundImage: `url(${img_5})` }}
        >
          <h4 className="font-bold text-2xl z-50 relative">
            أجرة المثل في نظام نزع ملكية
          </h4>
          <p className=" z-50 relative">
            امتناع الجهة المدعى عليها عن اتخاذ الإجراءات المنصوص عليها في نظام
            نزع ملكية العقارات للمنفعة العامة ووضع اليد المؤقت على العقار لتعويض
            المدعي بأجرة المثل غير موافق لنصوص المنظم، لذا فهي ملزمة بتشكيل
            اللجنة النظامية لتقدير أجرة المثل للجزء المنزوع من عقار المدعي من
            تاريخ وضع اليد ... حتى تاريخ صرف الشيك بمبلغ التعويض عن النزع .. حكم
            ديوان المظالم في القضية رقم 11460/1/ق لعام 1435هـ
          </p>
        </li>
        <li
          className="flex flex-col overflow-hidden justify-start gap-4 p-5 w-full h-96 bg-cover text-white relative after:bg-black after:absolute after:top-0 after:opacity-60 after:h-96 after:right-0 after:w-full"
          style={{ backgroundImage: `url(${img_6})` }}
        >
          <h4 className="font-medium text-md z-50 relative">
            القواعد والترتيبات الخاصة بكيفية معاملة الموظفين والعمال في القطاعات
            المستهدفة بالتحول والتخصيص
          </h4>
          <p className=" z-50 relative text-sm">
            قأوجبت القواعد والترتيبات الخاصة بكيفية معاملة الموظفين والعمال في
            القطاعات المستهدفة بالتحول والتخصيص الصادرة بقرار مجلس الوزراء 616
            وتاريخ 20/10/1442هـ على الجهات الحكومية في التعامل مع الموظفين لديها
            الراغبين في الانتقال إلى اللائحة الوظيفية (عقود) وتتوفر بهم المعايير
            بأن: ١ - تبرم الجهة المحول إليها عقد عمل معه وفق النظام الوظيفي
            المطبق فيها ووفق الأجور والمزايا وفق المعمول بها لديها، مع مراعاة
            الآتي: أ- ألا يقل الأجر الأساسي الذي سيتقاضاه عن الأجر الأساسي الذي
            كان يتقاضاه قبل الانتقال. ب ألا يقل الأجر الفعلي الذي سيتقاضاه عن
            الأجر الفعلي الذي كان يتقاضاه قبل الانتقال. ٢ - يعد اشتراكه في نظام
            التأمينات الاجتماعية مستمراً. ٣- تعد خدماته متصلة فيما يتعلق بمكافأة
            نهاية الخدمة والإجازات وتتحمل الجهة المحول إليها التكاليف المالية
            المترتبة على ذلك، وفق المادة الثامنة عشرة من نظام العمل
          </p>
        </li>
      </ul>
    </section>
  );
}
